import { useEffect } from 'react';

import mapValues from 'lodash/mapValues';

import { Option } from '@components/Formik/MultiSelectFilter/types';

import { getValuesFromOptions } from '@use-cases/districts';

import { useDistrictMembersSearch } from '@domui-hooks/useDistrictMembersSearch';
import {
  DistrictRosterFilter,
  Filter,
} from '@domui-presenters/web/components/DistrictMembersFilters/types';

export const useFetchMembersWithFilters = (
  currentPage: number,
  pageSize: number,
  filterValues: DistrictRosterFilter,
  selectedValues: Filter
) => {
  const {
    data,
    loading,
    error,
    districtMembersSearch,
  } = useDistrictMembersSearch();
  const fetchData = {
    totalCount: 0,
    districtMembersDetails: [],
  };
  const districtMembersSearchData = data?.status === 404 ? fetchData : data;

  const filters = mapValues(selectedValues, item =>
    getValuesFromOptions(item as Option[])
  );

  useEffect(() => {
    if (filterValues) {
      const memberTypeValues = selectedValues.memberTypes.map(
        item => item.value
      );
      const updatedFilterValues = {...filterValues}
      updatedFilterValues.filters.memberTypes = (memberTypeValues as unknown) as Option[];
      districtMembersSearch(currentPage, pageSize, updatedFilterValues);
    }
  }, [currentPage, pageSize, filterValues, selectedValues]);

  return {
    districtMembersSearchData,
    loading,
    error,
    filters,
  };
};
